import React from "react";
import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import SectionsHeading from "../../MainComponent/SectionsHeading";
import tokenImage from "../../../assets/Images/tokenImage.png";
import tokenHeading from "../../../assets/Images/tokenHeading.png";
import tokenBg from "../../../assets/Images/tokenBg.png";
import etherscan from "../../../assets/Images/etherscan.png";
import dextools from "../../../assets/Images/dextools.png";
import Uniswap from "../../../assets/Images/Uniswap.png";
import {
  FlexGrid,
  Image,
} from "../../Styles/style";
const ContinerWraper = styled(Container)`
  width: 100%;
  height: calc(100vh);
  padding: 30px 0 !important;
  background-image: url(${tokenBg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media(max-width: 599px){
    height: auto;
    background-position: right;

  }
`;

const Tokenomics = () => {
  return (
    <ContinerWraper maxWidth="xxl" id="cultnomics">
      <Container>
        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
        <FlexGrid item xs={12}>
            <Image mw="25%"  mw599="70%" src={tokenHeading} />
          </FlexGrid>
          <Grid item xs={12} sm={7}>
            <SectionsHeading p="60px 0 0" p599="60px 0 0" fs="32px" fs599="22px">
              Symbol CULT
            </SectionsHeading>
          </Grid>
          <FlexGrid item xs={12}>
            <Image mw="25%" mw599="40%" src={tokenImage} />
          </FlexGrid>
          <FlexGrid item jc="space-evenly" xs={12} sm={10} md={8}>
            <SectionsHeading p="10px 0 0" fs="32px" fs599="22px">
              Supply 100,000,000
            </SectionsHeading>
            <SectionsHeading p="10px 0 0" fs="32px" fs599="22px">
              Taxes 0/3
            </SectionsHeading>
          </FlexGrid>
          <FlexGrid item jc="center" fd599="column" p="30px 0" xs={12} sm={10} md={8} sx={{columnGap:"40px",rowGap:"20px"}}>
            <a href='https://etherscan.io/address/0x11aa56f5e3814f6313802ba065dcdea476565ab8'><Image mw="100px" src={etherscan} /></a>
            <a href='https://www.dextools.io/app/en/ether/pair-explorer/0xbdbceeb771e4827c13de371f34ad3f94de243a0f'> <Image mw="100px" src={dextools} /></a>
            <a href='https://app.uniswap.org/swap?outputCurrency=0x11Aa56F5e3814f6313802BA065dcdEA476565ab8&chain=ethereum'><Image mw="100px" src={Uniswap} /></a>
          </FlexGrid>
        </Grid>
      </Container>
    </ContinerWraper>
  );
};

export default Tokenomics;
