import React from "react";
import styled from "@emotion/styled";
import { Container, Grid, Link } from "@mui/material";
import Paragraph from "../../MainComponent/Paragraph";
import footerHeading from "../../../assets/Images/footerHeading.png";
import footerBg from "../../../assets/Images/footerBg.png";
import x from "../../../assets/Images/x.png";
import tg from "../../../assets/Images/tg.png";
import { FlexGrid, Image } from "../../Styles/style";
const ContinerWraper = styled(Container)`
  width: 100%;
  height: calc(100vh - 100px);
  padding: 30px 0 !important;
  background-image: url(${footerBg});
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  @media (max-width: 599px) {
    height: auto;
    background-position: right;
  }
`;

const Footer = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="joinus">
        <Container>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "start",
            }}
          >
            <FlexGrid item jc="start" p="100px 0 10px" xs={12}>
              <Image mw="60%" mw599="100%" src={footerHeading} />
            </FlexGrid>
            <FlexGrid
              item
              jc="start"
              xs={12}
              sm={10}
              md={8}
              sx={{ columnGap: "20px", rowGap: "10px" }}
            >
              <Link
                href="https://twitter.com/culttoken_erc20"
                target="blank"
                sx={{ textDecoration: "none" }}
              >
                <FlexGrid
                  item
                  jc="start"
                  p="30px 0"
                  sx={{ cursor: "pointer", columnGap: "10px" }}
                >
                  <Image mw="25px" src={x} />
                  <Paragraph fs="16px">X</Paragraph>
                </FlexGrid>
              </Link>
              <Link
                href="https://t.me/culttoken_erc20"
                target="blank"
                sx={{ textDecoration: "none" }}
              >
                <FlexGrid
                  item
                  jc="start"
                  p="30px 0"
                  sx={{ cursor: "pointer", columnGap: "10px" }}
                >
                  <Image mw="25px" src={tg} />
                  <Paragraph fs="16px">Telegram</Paragraph>
                </FlexGrid>
              </Link>
            </FlexGrid>
            <FlexGrid item jc="start" p="0px 0 10px" xs={12}>
              <Paragraph fs="18px">Email: info@ancientorder.vip</Paragraph>
            </FlexGrid>
          </Grid>
        </Container>
      </ContinerWraper>
      <FlexGrid
        href=""
        jc="center"
        p="5px 0"
        sx={{ background: "#000", cursor: "pointer" }}
      >
        <Paragraph fs="16px">© 2023 AncientOrderEth</Paragraph>
      </FlexGrid>
    </>
  );
};

export default Footer;
