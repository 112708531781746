import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import React from "react";
import heroBg from "../../../assets/Images/heroBg.png";
import heroHeading1 from "../../../assets/Images/heroHeading.png";
import { FlexGrid, Image } from "../../Styles/style";
import Paragraph from "../../MainComponent/Paragraph";

const CntainerWraper = styled(Container)`
  width: 100%;
  height: calc(100vh);
  padding: 0 !important;
  background-image: url(${heroBg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;
const GridWraper = styled(Grid)`
  height: calc(100vh);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-top: 20px;
`;

const HeroSection = ({isWelcome}) => {
  return (
    <CntainerWraper maxWidth="xxl" id="home">
      <Container>
        <GridWraper container>
          <FlexGrid p="0px 0 40px" fd="column" jc="end" item xs={12} sm={10}>
            <Image style={{transition:".7s ease-in"}} mw={isWelcome ? "100%" : "0%"} src={heroHeading1} />
            <Paragraph fs="22px" fs599="18px" p="0px 0 30px">
              Contract Address: 0x11Aa56F5e3814f6313802BA065dcdEA476565ab8
            </Paragraph>
          </FlexGrid>
          {/* <Grid item xs={12} sm={6}>
          </Grid> */}
        </GridWraper>
      </Container>
    </CntainerWraper>
  );
};

export default HeroSection;
