import styled from "@emotion/styled";
import { Container, Grid, Typography } from "@mui/material";
import menuBg from "../../../assets/Images/menuBg.png";
import heroHeading1 from "../../../assets/Images/heroHeading.png";
import { FlexGrid, Image } from "../../Styles/style";

const CntainerWraper = styled(Container)`
  width: 100%;
  height: ${props => props.isMenuOpen ? "calc(100vh)" :"0px"};
  padding: 0 !important;
  background-image: url(${menuBg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: end;
  position: fixed;
  z-index: 99999999;
  transition: height .3s ease-in;
  @media (max-width: 599px) {
    background-position: left;
    
  }
`;
const GridWraper = styled(Grid)`
  height: calc(100vh);
  display: flex;
  justify-content: start;
  align-items: center;
  padding-top: 20px;
`;

const Heading = styled(Typography)`
    padding: ${props=> props.p ? props.p : "0"};
    color:#707070;
    margin: ${props=> props.m ? props.m : "0"};
    text-align: ${props=> props.ta ? props.ta : "center"};
    font-size: ${props=> props.fs ? props.fs : "56px"};
    font-family: ${props=> props.fm ? props.fm : "Acadian"};
    text-shadow: ${props=> props.ts ? "0px 2px 10px #ffffff" :"none" };;
    text-transform: capitalize;
    z-index: 999;
    position: relative;
    :hover{
        color:red;
    }
    @media(max-width:899px){
        padding: ${props=> props.p899 ? props.p899 : "0px"};
    }
    @media(max-width:599px){
        font-size: ${props=> props.fs599 ? props.fs599 : "42px"};
        padding: ${props=> props.p599 ? props.p599 : "0px"};
    }
    @media (min-width: 1399px) {
      font-size: ${(props) => props.fs1399};
  }

`
const Menu = ({handleTogglebtn,isMenuOpen}) => {
  

  const handleBtnClick = (value) => {
    window.location.href = `#${value}`;
    handleTogglebtn();
  };
  return (
    <CntainerWraper maxWidth="xxl" isMenuOpen={isMenuOpen ? true : false}>
      <Container>
        <GridWraper container>
          <FlexGrid
            // p="200px 0 0"
            sx={{ width: "100%",height:"calc(100vh - 400px)", rowGap: "20px", }}
            fd="column"
            jc="start"
            ai="end"
            item
            xs={12}
          >
            <Heading
              sx={{ cursor: "pointer" }}
              onClick={() => handleBtnClick("home")}
            >
              Home
            </Heading>
            <Heading
              sx={{ cursor: "pointer" }}
              onClick={() => handleBtnClick("about")}
            >
              About
            </Heading>
            <Heading
              sx={{ cursor: "pointer" }}
              onClick={() => handleBtnClick("cultnomics")}
            >
              Cultnomics
            </Heading>
            <Heading
              sx={{ cursor: "pointer" }}
              onClick={() => handleBtnClick("joinus")}
            >
              Join Cult
            </Heading>
          </FlexGrid>
          <FlexGrid
            p="0px 0 40px"
            sx={{ width: "100%"}}
            fd="column"
            jc="start"
            ai="start"
            item
            xs={12} sm={8} md={6}
          >
            <Image mw="100%" src={heroHeading1} />
          </FlexGrid>
        </GridWraper>
      </Container>
    </CntainerWraper>
  );
};

export default Menu;
