import React from "react";
import { Container, Grid } from "@mui/material";
import { FlexGrid, Image } from "../../Styles/style";
import styled from "@emotion/styled";
import aboutHeading from "../../../assets/Images/aboutHeading.png";
import aboutBg from "../../../assets/Images/aboutBg.png";
import Paragraph from "../../MainComponent/Paragraph";

const ContinerWraper1 = styled(Container)`
  /* background: radial-gradient(circle at 50% 60%, #1177f37a, transparent 50%); */
  width: 100%;
  height: calc(100vh);
  padding: 0 !important;
  background-image: url(${aboutBg});
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
  @media (max-width: 599px) {
    background-size: 100% 20%;
    height: auto;
  }
  
`;

const About = () => {
  return (
    <>
      <ContinerWraper1 maxWidth="xxl" id="about">
        <Container sx={{ padding: "0 !important" }} id="stats">
          <Grid container>
            <FlexGrid item p="30px 0" xs={12}>
              <Image mw="25%" mw599="60%" src={aboutHeading} />
            </FlexGrid>
          </Grid>
          <Grid
            container
            sx={{
              height: "calc(100vh - 300px)",
              columnGap: "20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid
              container
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid item xs={12} sm={10} md={7}>
                <Paragraph p599="10px" ta="center">
                  In the digital realm, the Cult of Kosmos emerged as an
                  enigmatic force, their dark ambitions extending beyond the
                  shadows of mere mortal transactions. With relentless cunning,
                  they infiltrated the Ethereum network, manipulating the
                  blockchain's very code to fuel their sinister desires. Once
                  noble, the Ethereum blockchain became a battleground for power
                  as the Cult harnessed its decentralized might for their own
                  gain. <br />
                  Cryptocurrency users worldwide watched in trepidation as the
                  Cult of Kosmos took control, causing chaos in the digital
                  domain. The network's decentralization, once a symbol of
                  trust, became a weapon of manipulation, sparking a battle
                  between defenders of the blockchain's integrity and those who
                  sought to exploit its newfound vulnerabilities. The future of
                  digital currency hung in the balance as the Cult of Kosmos
                  tightened its grip on the Ethereum network, leaving a path of
                  uncertainty and intrigue in their wake.
                </Paragraph>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </ContinerWraper1>
    </>
  );
};

export default About;
