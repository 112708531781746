import styled from "@emotion/styled";
import { Grid } from "@mui/material";

const PageWrapper = styled.div`
  background-color: #000;
  /* background: radial-gradient(circle at 50% 60%, #1177f37a, transparent 80%); */
  overflow: auto;
  height: 100vh;
  
`;
const Image = styled.img`
  width: 100%;
  max-width: ${(props) => (props.mw ? props.mw : "30px")};
  margin: ${(props) => (props.m ? props.m : "0")};
  position: relative;
  z-index: 9;
  transition: 0.4s ease-in-out;
  
  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */
  @media (max-width: 599px) {
    max-width: ${(props) => (props.mw599 ? props.mw599 : "")};
    margin: ${(props) => (props.m599 ? props.m599 : "0")};
    display: ${(props) => (props.disp ? props.disp : "")};
    visibility: ${(props) => (props.vis ? props.vis : "")};
  }
`;
const GridWraper = styled(Grid)`
  width: 100%;
  min-height: calc(100vh - 90px);
  position: relative;
  display: flex;
  align-content: space-between;
  justify-content: center;
`;


const FlexGrid = styled(Grid)`
  display: flex;
  flex-direction: ${(props) => (props.fd ? props.fd : "row")};
  align-items: ${(props) => (props.ai ? props.ai : "center")};
  justify-content: ${(props) => (props.jc ? props.jc : "center")};
  padding: ${(props) => (props.p ? props.p : "0px")};
  margin: ${(props) => (props.m ? props.m : "0")};
  @media (max-width: 700px){
    flex-direction: ${(props) => (props.fd599 ? props.fd599 : "")};;
    padding-top: 20px;
  }
`;
export {
  PageWrapper,
  Image,
  GridWraper,
  FlexGrid,
};
