import React, { useState } from "react";
import { FlexGrid, Image, PageWrapper } from "../../Styles/style";
import HeroSection from "../../Sections/S1-Header";
import About from "../../Sections/S2-About";
import Tokenomics from "../../Sections/S3-Tokenomics";
import Footer from "../../Sections/S4-Footer";
import smoke from "../../../assets/Images/smoke.mp4";
import welcome from "../../../assets/Images/welcome.png";
import heroHeading from "../../../assets/Images/heroHeading.png";
import yes from "../../../assets/Images/yes.png";
import no from "../../../assets/Images/no.png";
import logo from "../../../assets/Images/logo.png";
import menu from "../../../assets/Images/menu.png";
import close from "../../../assets/Images/close.png";
import music from "../../../assets/Images/music.mp3";
import styled from "@emotion/styled";
import { Container, Grid, Link } from "@mui/material";
import Menu from "../../Components/Menu";
import Paragraph from "../../MainComponent/Paragraph";

const VIDEO = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: fixed;
  z-index: 0;
  opacity: 1;
  mix-blend-mode: plus-lighter;
`;
const ContainerWraper = styled(Container)`
  width: 100%;
  height: calc(100vh);
  padding: 0 !important;
  background: linear-gradient(transparent, #ff0000);
  position: fixed;
  z-index: 999999999999999999999999;
`;
const GridWraper = styled(Grid)`
  width: 100%;
  height: 400px;
  background-image: url(${welcome});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: ${(props) => (props.fd ? props.fd : "column")};
  align-items: ${(props) => (props.ai ? props.ai : "center")};
  justify-content: ${(props) => (props.jc ? props.jc : "center")};
  padding: ${(props) => (props.p ? props.p : "0px")};
  margin: ${(props) => (props.m ? props.m : "0")};
  @media (max-width: 700px) {
    flex-direction: ${(props) => (props.fd599 ? props.fd599 : "")};
    padding-top: 20px;
  }
`;
const MainPage = (props) => {
  const [isWelcome, setIsWelcome] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const handleToggle = () => {
    setMenuOpen(!menuOpen);
  };
  const playAudio = () => {
    setIsPlaying(true);
    setIsWelcome(true);
  };

  return (
    <PageWrapper>
      <VIDEO muted loop autoPlay>
        <source src={smoke} type="video/mp4"></source>
      </VIDEO>
      {isPlaying && (
        <audio autoPlay loop>
          <source src={music} type="audio/mpeg" />
        </audio>
      )}
      {isWelcome ? (
        ""
      ) : (
        <ContainerWraper maxWidth="xxl">
          <Container>
            <Grid
              container
              sx={{
                display: "grid",
                placeItems: "center",
                height: "100vh",
                width: "100%",
              }}
            >
              <GridWraper
                item
                jc="space-between"
                ai="center"
                p="20px"
                xs={12}
                sm={10}
                md={6}
                sx={{ columnGap: "40px", rowGap: "20px" }}
              >
                <FlexGrid sx={{ columnGap: "40px", rowGap: "20px" }}>
                  <Image mw="100%" mw599="100%" src={heroHeading} />
                </FlexGrid>
                <FlexGrid fd="column">
                  <FlexGrid>
                    <Paragraph fs="18px">
                      Are you ready to enter? <br /> There is no going back.
                    </Paragraph>
                  </FlexGrid>
                  <FlexGrid sx={{ columnGap: "40px", rowGap: "20px" }}>
                    <Image
                      onClick={() => playAudio()}
                      style={{ cursor: "pointer" }}
                      mw="150px"
                      mw599="100px"
                      src={yes}
                    />
                    <Image mw="150px" mw599="100px" src={no} />
                  </FlexGrid>
                </FlexGrid>
              </GridWraper>
            </Grid>
          </Container>
        </ContainerWraper>
      )}
      <>
        <Container
          maxWidth="xxl"
          sx={{
            position: "fixed",
            zIndex: 999999999,
            background: "linear-gradient(#000 30%, transparent)",
          }}
        >
          <Container>
            <Grid container>
              <FlexGrid
                item
                sx={{ width: "100%" ,position:"relative"}}
                xs={12}
                p="10px 0"
                jc="space-between"
              >
                <Link href="/">
                  <Image
                    mw="70px"
                    mw599="60px"
                    src={logo}
                    style={{ cursor: "pointer" }}
                  />
                </Link>
                <Image
                  className={menuOpen ? "open" : "close"}
                  mw="100px"
                  mw599="100px"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleToggle()}
                  src={close}
                />
                <Image
                  className={menuOpen ? "close" : "open"}
                  mw="100px"
                  mw599="100px"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleToggle()}
                  src={menu}
                />
              </FlexGrid>
            </Grid>
          </Container>
        </Container>
        <Menu handleTogglebtn={handleToggle} isMenuOpen={menuOpen} />
        <HeroSection isWelcome={isWelcome}/>
        <About />
        <Tokenomics />
        <Footer />
      </>
      {/* <PageWrapper1>
        <Partner />
        <AboutSection />
      </PageWrapper1>
      <PageWrapper1>
        <FundSection />
      </PageWrapper1> */}
    </PageWrapper>
  );
};

export default MainPage;
