import styled from '@emotion/styled'
import { Typography } from '@mui/material'
import React from 'react'

const Heading = styled(Typography)`
    padding: ${props=> props.p ? props.p : "0"};
    margin: ${props=> props.m ? props.m : "0"};
    text-align: ${props=> props.ta ? props.ta : "center"};
    font-size: ${props=> props.fs ? props.fs : "56px"};
    font-family: ${props=> props.fm ? props.fm : "Acadian"};
    text-shadow: ${props=> props.ts ? "0px 2px 10px #ffffff" :"none" };;
    text-transform: capitalize;
    z-index: 999;
    position: relative;
    @media(max-width:899px){
        padding: ${props=> props.p899 ? props.p899 : "0px"};
    }
    @media(max-width:599px){
        font-size: ${props=> props.fs599 ? props.fs599 : "42px"};
        padding: ${props=> props.p599 ? props.p599 : "0px"};
    }
    @media (min-width: 1399px) {
      font-size: ${(props) => props.fs1399};
  }
`

const SectionsHeading = (props) => {
    const {children} = props
  return (
    <Heading color="#ffffff" {...props}>
        {children}
    </Heading>
  )
}

export default SectionsHeading